import { Route, Switch } from 'react-router-dom';
import {
  AnalyticsMenu,
  AnalyticsMenuCategory,
  AnalyticsMenuItem,
  AnalyticsPageWithActions,
  Breadcrumbs,
  KnownsDemographicBoard,
  KnownsPsychographicConsumptionBoard,
  KnownsPsychographicIndividualBoard,
  KnownsPsychographicSocietyBoard,
  Page,
  useAnalytics,
  useAnalyticsFilterState,
  VStack,
} from '@knowns/common';

const ANALYTICS_MENU: (AnalyticsMenuCategory | AnalyticsMenuItem)[] = [
  {
    type: 'analytics',
    id: `${KnownsDemographicBoard.Meta.target}:${KnownsDemographicBoard.Meta.analyticsType}`,
    ...KnownsDemographicBoard.Meta,
  },
  {
    type: 'category',
    name: 'サイコグラフィック',
    children: [
      KnownsPsychographicIndividualBoard.Meta,
      KnownsPsychographicConsumptionBoard.Meta,
      KnownsPsychographicSocietyBoard.Meta,
    ].map((i) => ({
      ...i,
      id: `${i.target}:${i.analyticsType}`,
      type: 'analytics',
    })),
  },
];

export const SettingsAnalyticsKnownsPage: React.FC = () => {
  const { menuItems, onChangeAnalytics, activeAnalytics } = useAnalytics({
    menuItems: ANALYTICS_MENU,
  });
  const filterState = useAnalyticsFilterState({});

  return (
    <AnalyticsPageWithActions filterState={filterState} activeAnalyticsId={activeAnalytics?.id}>
      <Page.Breadcrumbs>
        <Breadcrumbs>
          <Breadcrumbs.Item url='/settings'>アプリユーザー</Breadcrumbs.Item>
          <Breadcrumbs.Item>アプリユーザーパネル</Breadcrumbs.Item>
        </Breadcrumbs>
      </Page.Breadcrumbs>
      <Page.Content width='100%'>
        <VStack gap={32}>
          <AnalyticsMenu items={menuItems} onChangeAnalytics={onChangeAnalytics} />
          <Switch>
            <Route exact path={KnownsDemographicBoard.Meta.url()}>
              <KnownsDemographicBoard />
            </Route>
            <Route exact path={KnownsPsychographicConsumptionBoard.Meta.url()}>
              <KnownsPsychographicConsumptionBoard />
            </Route>
            <Route exact path={KnownsPsychographicIndividualBoard.Meta.url()}>
              <KnownsPsychographicIndividualBoard />
            </Route>
            <Route exact path={KnownsPsychographicSocietyBoard.Meta.url()}>
              <KnownsPsychographicSocietyBoard />
            </Route>
          </Switch>
        </VStack>
      </Page.Content>
    </AnalyticsPageWithActions>
  );
};
export default SettingsAnalyticsKnownsPage;
